.footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    z-index: 999;
    position: relative;
}

.green{
    background: #28B463;
    width: 95%;
    height: 30px;
    border-radius: 30px 30px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.yellow{
    background: #FFE221;
    width: 98%;
    height: 20px;
    border-radius: 30px 30px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.red{
    background: #FF0000;
    width: 98%;
    height: 10px;
    border-radius: 30px 30px 0 0;
}

.footerContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
    width: 100%;
    background: #000000;
    color: #F7F7FF;
}

.footerContent img{
    width: 150px;
}

.footerContent p{
    font-size: 0.8rem;
}
