.info{
    padding: 10%;
    align-self: flex-start;
}

.heading{
    font-family: 'Bebas Neue', cursive;
    font-size: 1.5rem;
    margin-top: 30px;
    line-height: 2.2rem;
}

.para{
    font-size: 0.8rem;
}

.points{
    margin-top: 20px;
}