.home{
    margin: 5px 10% 0 10%;
    min-height: 90vh;
    background: url("/src/assets/img/bg.png") center;
    background-size: cover;
}

.content{
    background: rgba(255, 255, 255, 0.7);
    min-height: 90vh;
    min-width: 80%;
    backdrop-filter: blur(1px);
    display: grid;
    grid-template-columns: 0.6fr 0.3fr 0.1fr;
    align-items: center;
    position: absolute;
}

.homeQuote{
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
    margin: 0 20px;
}

.homeQuoteContainer{
    display: flex;
    align-items: center;
}

.homeDownloadTxt{
    font-family: 'Bebas Neue', cursive;
    margin: 40px 0;
    font-size: 1.1rem;
}

.homeLogo{
    width: 40px;
    object-fit: cover;
}

.homeDownloadContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.homeBanner{
    width: 80%;
    position: relative;
    z-index: 9;
    bottom: -30px;
    align-self: flex-end;
}

.homeBannerContainer{
    display: flex;
    align-items: center;
    flex-direction: column;
}

.actionsContainer{
    background: #F7F7FF;
    width: 100%;
    height: 60px;
    border-radius: 20px;
    padding: 20px 30px;
    box-shadow: -4px 7px 3px #D7DAE5;
}

.actionLogo{
    width: 70px;
}

.homeSocialContainer{
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    margin-top: 100px;
}

.homeSocialContainer span, .homeSocialContainer span a{
    margin-bottom: 50px;
    color: #8B9EB7;
}

.homeSocialContainer span:last-child{
    margin-bottom: 0;
}

.downloadBadge{
    height: 58px;
    margin-left: 20px;
}

.homeBadgeContainer{
    display: flex;
    align-items: center;
}

.actionButtons{
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
}

.helpContainer{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.helpBox{
    min-width: 40%;
    min-height: 60%;
    background: #F7F7FF;
    border-radius: 20px;
    padding: 20px 30px;
    box-shadow: -4px 7px 3px rgba(0, 0, 0, 0.3);
}

.helpHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.helpHeader span{
    cursor: pointer;
    font-size: 1.3rem;
}

.helpBox p{
    color: #FF0000;
    text-decoration: underline;
    font-weight: bold;
    text-align: center;
}

.normal{
    color: black !important;
    text-decoration: none !important;
}

.noUnderline{
    text-decoration: none !important;
}

.helpIcon{
    margin-right: 3px;
}

.helpQuote{
    display: grid;
    grid-template-columns: 0.25fr 0.5fr 0.25fr;
    align-items: center;
    text-align: center;
}

.helpBtn{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}

.helpQuote h1{
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
}