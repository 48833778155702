.about{
    margin: 5px 10% 0 10%;
    min-height: 90vh;
    background: url("/src/assets/img/bg.png") center;
    background-size: cover;
}

.content{
    background: rgba(255, 255, 255, 0.7);
    min-height: 90vh;
    min-width: 80%;
    backdrop-filter: blur(1px);
    display: grid;
    grid-template-columns: 0.5fr 0.5fr;
    align-items: center;
    position: absolute;
}

.aboutBanner{
    width: 400px;
    position: relative;
    z-index: 9;
    align-self: flex-start;
    top: -95px;
    left: -85px;
}

.aboutImgContainer{
    position: relative;
    display: flex;
    flex-direction: column;
    object-fit: cover;
}

.aboutLogo{
    width: 280px;
    align-self: flex-end;
    margin-right: 60px;
}