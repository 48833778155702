.navbar{
    height: 80px;
    box-shadow: 0 3px 3px #D7DAE5;
    padding: 0 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo{
    width: 90px;
    object-fit: cover;
    cursor: pointer;
}

.menu{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.menuItem{
    font-family: 'Bebas Neue', cursive;
    margin-right: 5%;
}