.socialPanel{
    background: #D7DAE5;
    padding: 7px 20px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.socialPanel span{
    margin-right: 25px;
    color: #979DAA;
    cursor: pointer;
}

.socialPanel span:last-child{
    margin-right: 0;
}