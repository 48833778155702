.quotes{
    margin: 5px 10% 0 10%;
    min-height: 90vh;
    background: url("/src/assets/img/bg.png") center;
    background-size: cover;
}

.content{
    background: rgba(255, 255, 255, 0.7);
    min-height: 90vh;
    min-width: 80%;
    backdrop-filter: blur(1px);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.headingContainer{
    margin-left: 20%;
}

.quotesContainer{
    display: grid;
    grid-template-columns: 0.2fr 0.1fr 0.4fr 0.1fr 0.2fr;
    align-items: center;
    width: 100%;
    text-align: center;
    margin: 50px 0;
}

.sideQuote{
    color: #8B9EB7;
}

.mainQuote{
    font-size: 1.5rem;
    font-weight: bold;
}

.actionBtnContainer{
    margin-bottom: 30px;
}

.actionBtn{
    padding: 20px;
    border-radius: 50%;
    background: #F7F7FF;
    color: #8B9EB7;
    margin-right: 40px;
}

.actionBtn:last-child{
    margin-right: 0;
}