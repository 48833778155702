.heading{
    display: flex;
    align-items: center;
}

.heading span{
    font-family: 'Bebas Neue', cursive;
    font-size: 1.1rem;
}

.heading img{
    width: 7%;
    margin-right: 3px;
}