.point{
    display: flex;
    align-items: flex-start;
    margin-top: 10px;
}

.point img{
    width: 5%;
    margin-right: 3px;
}

.point span{
    font-size: 0.75rem;
}